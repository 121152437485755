<template>
    <div class="container flex flex-col">
        <div class="mx-auto w-full max-w-960 mt-8 mb-8 p-4 bg-white-pure l-box-shadow rounded">
            <!--need to change element to vuetify on tis pg -->
            <v-form
                v-model="changePasswordForm"
                ref="changePasswordForm"
                data-app
            >
                <h1 class="text-black mr-2 mb-8 text-xl lg:text-2xl text-center" data-cy="accountSettingsTitle">Change Password</h1>
                <v-text-field hide-details v-model="this.$auth.user.email" placeholder="Email" disabled label="Email"></v-text-field>
                <div class="grid grid-cols-2 gap-x-4 w-full">
                    <div class="col-span-1">
                        <v-text-field
                        v-model="changePasswordForm.password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[passwordRules.min, passwordRules.max]"
                        :type="show1 ? 'text' : 'password'"
                        hint="At least 8 characters"
                        label="New Password">

                        </v-text-field>
                    </div>
                    <div class="col-span-1">
                        <v-text-field
                        v-model="changePasswordForm.confirmPassword"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[passwordRules.min, passwordRules.max]"
                        :type="show1 ? 'text' : 'password'"
                        label="Confirm Password">
                        </v-text-field>
                    </div>
                </div>
                <div class="flex flex-row justify-center mt-8">
                    <v-btn depressed
                        class="mr-4 w-40"
                        @click="goBack()"
                    >Cancel
                    </v-btn>
                    <v-btn depressed
                        class="v-btn--flat inline bg-green-light w-40"
                        type="success" color="success"
                        @click="validate()"
                    >Save
                    </v-btn>
                </div>
            </v-form>
        </div>
    </div>
</template>

<script>
import { AuthMixin } from '@/components/shared/mixins/authHelpers'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import HistoryFns from '@/components/shared/mixins/historyFns'
import { mapState } from 'vuex'
import { FormRules } from '@/components/shared/mixins/formMixins'

export default {
    name: "ChangePassword",
    mixins: [AuthMixin, MessageDialog, HistoryFns, FormRules],
    data() {
        return {
            changePasswordForm: {
                password: '',
                confirmPassword: ''
            },
            validationRules: {
                password: [
                    { required: true, message: 'Please enter a secure password', trigger: 'blur' },
                    { min: 8, max: 16, message: 'Please enter 8 - 16 characters', trigger: 'blur' },
                ],
                confirmPassword: [
                    { required: true, message: 'Please enter a secure password', trigger: 'blur' },
                    { min: 8, max: 16, message: 'Please enter 8 - 16 characters', trigger: 'blur' },
                    { validator: this.validateConfirmPassword, trigger: 'blur' }
                ],
            },
            duration: 3000,
            defaultRoute: {
                name: 'PracticeList'
            },
            passwordRules: {
                required: this.required,
                min: this.min,
                max: this.max,
              }
        }
    },
    computed: {
        ...mapState({
            routeFrom: state => state.app.routeFrom,
        }),
    },
    methods: {
        validateConfirmPassword(rule, value, callback) {
            if (value !== this.changePasswordForm.password) {
                callback(new Error('Password does not match'));
            } else {
                callback();
            }
        },
        validate() {
            this.$refs.changePasswordForm.validate()
            .then(res => {
                this.$http({
                    url: `web.user/${this.userId}/`,
                    method: 'PATCH',
                    data: {
                        password: this.changePasswordForm.password
                    }
                }).then(res => {
                    this.message = 'Password has been updated.'
                    this.type = 'success'
                    this.resetChangePasswordForm()
                }).catch((err => {
                    this.message = 'Error updating your password'
                    this.type = 'error'
                })).finally(() => {
                    this.showMessage({duration: this.duration})
                })

            }).catch(err => {
            })
        },
        resetChangePasswordForm() {
            this.changePasswordForm.password = ''
            this.changePasswordForm.confirmPassword = ''
        }
    }
}
</script>
